<template>
  <div class="regin">
    <div class="line">
      <!-- 英文 -->
      <div v-if="language_id == 1">
        <div>TERMS OF USE</div>
        <div>
          entered into a related web services (hereinafter referred to as services) of the following agreement (the "Terms
          of Use").

          

        </div>
      </div>
      <!-- 简体中文 -->
      <div v-else-if="language_id == 2">
        <div>服务条款</div>
        <div>
          在以下条款中，“用户”是指向注册成为本公司用户的个人或者单位。用户同意此在线注册条款之效力如同用户亲自签字、盖章的书面条款一样，对用户具有同等的法律效力。用户进一步同意，用户进入本公司会员注册程序即意味着用户同意了本注册条款的所有内容且只有用户完全同意所有服务条款并完成注册程序，才能成为本公司的正式用户。
          服务条款
          。
        </div>
      </div>
      <!-- 繁体中文 -->
      <div v-else>
        <div>服務條款</div>
        <div>
          在以下条款中，“用户”是指向注册成为本公司用户的个人或者单位。用户同意此在线注册条款之效力如同用户亲自签字、盖章的书面条款一样，对用户具有同等的法律效力。用户进一步同意，用户进入本公司会员注册程序即意味着用户同意了本注册条款的所有内容且只有用户完全同意所有服务条款并完成注册程序，才能成为本公司的正式用户。
          服务条款
          
        </div>
      </div>


    </div>


  </div>
</template>

<script>
  import {
    RadioGroup,
    Radio,
    DatetimePicker,
    Popup,
    Cascader
  } from "vant";
  import {
    RequestApi
  } from "@/apis/demo.js";
  import Cookies from 'js-cookie';
  import {
    getQueryVariable
  } from "@/utils/request";
  export default {
    name: "regin",
    props: {},
    data() {
      return {
        language_id: 2, //默认语言id， 1，英文 2，简体中文 3，繁体中文
        sex: "1",
        birthday: new Date(2001, 1, 15),
        maxDate: new Date(),
        minDate: new Date(1900, 1, 1),
        birthdayActive: false,
        birthdayText: "点击选择生日...",
        nationActive: false,
        nationText: "点击请选择地区...",
        nationOptions: [{
            text: "中国",
            value: "021",
          },
          {
            text: "美国",
            value: "030",
            // children: [{ text: '南京市', value: '320100' }],
          },
        ],
        radio: '1'
      };
    },
    components: {
      [RadioGroup.name]: RadioGroup,
      [DatetimePicker.name]: DatetimePicker,
      [Radio.name]: Radio,
      [Popup.name]: Popup,
      [Cascader.name]: Cascader,
    },
    beforeMount() {
      const language_id = Cookies.get('language_id') || 2; //2022-3-9 按照客户要求默认改为简体字 //客户现在要求默认是繁体
      this.language_id = language_id;
    },
    mounted() {},
    methods: {
      getRadioVal() {
        //获取性别的选择
        console.log(this.radioVal);
      },
      onFinish({
        selectedOptions
      }) {
        this.nationActive = false;
        this.nationText = selectedOptions.map((option) => option.text).join("/");
      },
      async regin() {
        //注册
        if (this.$refs.userCode.value == "") {
          Toast("请输入账号");
          return;
        };
        if (this.$refs.email.value == "") {
          Toast("请输入邮箱");
          return;
        };
        var re = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/
        if (re.test(this.$refs.email.value)) {

        } else {
          Toast("邮箱验证不通过!");
          return false;
        }
        if (this.$refs.pwd.value == "") {
          Toast("请输入密码");
          return;
        };
        if (this.$refs.pwd.value != this.$refs.pwd2.value) {
          Toast("两次密码输入不一致");
          return;
        };
        var that = this;
        var uri = "";
        uri = this.$global.domain + "/tools/post.ashx";
        var param = {
          act: "adduser",
          tel: '',
          userpwd: this.$refs.pwd.value,
          yzm: '', //客户无短信，不需要短信验证码
          username: this.$refs.userCode.value, //目前注册没有名字，所以等于账号
          sex: this.sex,
          kh: this.$refs.userCode.value, //会员的用户名
          birthday: this.birthdayText,
          address: this.nationText,
          email: this.$refs.email.value,
          headimg: 'http://xcx01.keduozan.com/images/mrtx.jpg', //传一个默认头像
          fkfd: '', //这个会员对应的会员等级，不传值，后台自动用默认的等级
          sitenum: "18777777777",
          openid: "",
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          //注册成功后自动登录
          Cookies.set("kh", this.$refs.userCode.value);
          Cookies.set("openid", this.openid);
          Cookies.set("token", this.token); //每次登陆，token会变化，并且根据有效期会超时
          //跳转
          this.$router.push("home");
        } else {
          Toast(result.rsg);
        }

        // that.userName = result.name;
        // that.userAddress = result.address;
      },
      // 选项格式化函数
      formatter(type, value) {
        if (type === "year") {
          return `${value}年`;
        } else if (type === "month") {
          return `${value}月`;
        } else if (type === "day") {
          return `${value}日`;
        } else if (type === "hour") {
          return `${value}时`;
        } else if (type === "minute") {
          return `${value}分`;
        } else if (type === "second") {
          return `${value}秒`;
        }
        return value;
      },
      selBirthDay(val) {
        let year = val.getFullYear();
        let month = val.getMonth() + 1;
        let day = val.getDate();
        let hour = val.getHours();
        let minute = val.getMinutes();
        if (month >= 1 && month <= 9) {
          month = `0${month}`;
        }
        if (day >= 1 && day <= 9) {
          day = `0${day}`;
        }
        if (hour >= 0 && hour <= 9) {
          hour = `0${hour}`;
        }
        if (minute >= 0 && minute <= 9) {
          minute = `0${minute}`;
        }
        this.birthdayText = `${year}-${month}-${day} ${hour}:${minute}`;
        this.birthdayActive = false;
        console.log(this.birthdayText);
      },
    },
    computed: {},
    watch: {},
  };
</script>

<style scoped lang="less">
  .regin {
    .title {
      height: 68px;
      line-height: 68px;
      width: 348px;
      position: relative;
      margin: 0 auto;
      font-size: 18px;
      font-weight: bold;
      text-align: left;
      color: #3d3d3d;
    }

    .line {
      height: 54px;
      line-height: 54px;
      width: 348px;
      position: relative;
      margin: 0 auto;
      margin-bottom: 18px;
      font-size: 14px;
      text-align: left;
      color: #6e6e6e;

      // border: 1px red dashed;
      input {
        height: 50px;
        border: 1px #f2f2f2 solid;
        width: 344px;
        text-indent: 12px;
        color: #464646;
        text-indent: 70px;
      }

      .superIndent {
        text-indent: 120px;
      }

      .lable {
        text-indent: 12px;
        display: inline-block;
        // width: 76px;
        position: absolute;
      }

      .sexGroup {
        position: relative;
        // border: 1px red solid;
        width: 210px;
        margin-top: 17px;
        margin-left: 70px;
        display: inline-block;

      }

      .suojin {
        margin-left: 16px;
        left: 56px;
        position: relative;
      }

      .greenColor {
        position: relative;
        // border: 1px red solid;
        width: 280px;
        margin-top: 17px;
        margin-left: 30px;
        display: inline-block;

        i {
          color: #62c3ab;
          font-style: initial;
        }
      }
    }
  }

  .bottomBtn {
    position: relative;
    margin: 22px auto 90px auto;
    width: 354px;
    height: 54px;
    line-height: 54px;
    border-radius: 40px;
    background-color: #ea2e3a;
    color: white;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
</style>
